<template>
  <div>
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(submit)">
        <div id="container " class="md-layout md-gutter md-alignment-center">
          <div class="md-layout-item">
            <h4 class="title" style="font-weight:350;">
              Novo endereço :
            </h4>
          </div>
          <div>
            <md-button
              type="button"
              class="md-raised mt-4 md-success"
              @click="addNovoEndereco()"
            >
              Adicionar
            </md-button>
          </div>
        </div>
        <div v-for="(endereco, index) in enderecos" :key="index">
          <div
            v-if="!isFetching"
            style="padding-top:15px;display:inline-block;"
          >
            <span>
              <h5 class="title" style="font-weight:350;padding-bottom: 10px;">
                Endereço - {{ index }}
              </h5></span
            >
          </div>
          <div id="agrupamentoCampos" class="md-layout">
            <div class="md-layout-item md-small-size-100 md-size-15">
              <ValidationProvider
                :name="'cep-' + index"
                :rules="{
                  regex: /^[0-9]{5}-[0-9]{3}$/,
                  min: 9,
                  max: 9,
                  required: true
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>CEP</label>
                  <md-input
                    name="enderecos[][cep]"
                    v-model="endereco.cep"
                    type="text"
                    :maxlength="9"
                  ></md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ "CEP deve ter formato 99999-999." }}
                </span>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-50">
              <ValidationProvider
                :name="'logradouro-' + index"
                :rules="{
                  min: 3,
                  max: 100,
                  required: true
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Logradouro</label>
                  <md-input
                    v-model="endereco.logradouro"
                    type="text"
                    :maxlength="100"
                  ></md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-15">
              <ValidationProvider
                :name="'numero-' + index"
                :rules="{
                  regex: /^\s*[0-9]+?$|^$|^\s*$/,
                  min: 1,
                  max: 7,
                  required: true
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Numero</label>
                  <md-input
                    v-model="endereco.numeroLog"
                    type="text"
                    :maxlength="7"
                  ></md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-25">
              <ValidationProvider
                :name="'complemento-' + index"
                :rules="{
                  min: 1,
                  max: 50
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Complemento</label>
                  <md-input
                    v-model="endereco.complemento"
                    type="text"
                    :maxlength="7"
                  ></md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-25">
              <ValidationProvider
                :name="'bairro-' + index"
                :rules="{
                  min: 3,
                  max: 50,
                  required: true,
                  alpha_spaces: true
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Bairro</label>
                  <md-input
                    v-model="endereco.bairro"
                    type="text"
                    :maxlength="100"
                  ></md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-50">
              <ValidationProvider
                :name="'cidade-' + index"
                :rules="{
                  min: 3,
                  max: 100,
                  required: true,
                  alpha_spaces: true
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Cidade</label>
                  <md-input
                    v-model="endereco.cidade"
                    type="text"
                    :maxlength="100"
                  ></md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-15">
              <ValidationProvider
                :name="'uf-' + index"
                :rules="{
                  min: 2,
                  max: 2,
                  required: true
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>UF</label>
                  <md-input
                    v-model="endereco.uf"
                    type="text"
                    :maxlength="2"
                  ></md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-33">
              <ValidationProvider
                :name="'país-' + index"
                :rules="{
                  alpha_spaces: true,
                  min: 3,
                  max: 100,
                  required: true
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>País</label>
                  <md-input
                    v-model="endereco.pais"
                    type="text"
                    :maxlength="100"
                  ></md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-33">
              <ValidationProvider
                :name="'tipo endereço-' + index"
                :rules="{
                  required: true
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label for="tipoEnderecoSelect"
                    >Selecionar Tipo Endereço</label
                  >
                  <md-select
                    v-model="endereco.tipoEndereco"
                    name="tipoEnderecoSelect"
                  >
                    <md-option
                      v-for="item in tipoEnderecoSelectOption"
                      :key="item.id"
                      :label="item.tipo_endereco"
                      :value="item.tipo_endereco"
                    >
                      {{ item.tipo_endereco }}
                    </md-option>
                  </md-select>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
          </div>
          <div class="col-xs-2">
            <label>&nbsp;</label>
            <md-button
              type="button"
              class="md-just-icon md-danger"
              @click="removeEndereco(index)"
            >
              <md-icon>close</md-icon>
            </md-button>
          </div>
        </div>
        <div class="col-xs-2">
          <md-button type="submit" class="md-raised mt-4 md-success">
            CRIAR
          </md-button>
          &nbsp;
          <md-button
            @click="validarProximo()"
            class="md-raised md-success mt-4"
          >
            PROXIMO >
          </md-button>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { extend, localize } from "vee-validate";
import pt_BR from "vee-validate/dist/locale/pt_BR.json";
import { store } from "../../../store";
import { mapState, mapActions, mapGetters } from "vuex";
import * as rules from "vee-validate/dist/rules";
import { NavTabsCard } from "@/components";
import Vue from "vue";
import Swal from "sweetalert2";
import { ESTADOS } from "@/pages/Dashboard/js/estados";
import { NOME_CAMPOS } from "@/pages/Dashboard/js/nomeCampos";
import { cepMask } from "@/pages/Dashboard/js/cepMask";
import { checkAllResponses } from "@/modules/servidor_publico/js/checkAllResponses";
import { checkAllResponsesPensionista } from "@/modules/pensionista/js/checkAllResponsesPensionista";

var pt_br = pt_BR;
var names = NOME_CAMPOS;
Vue.set(pt_br, "names", names);
for (let key in pt_br.messages) {
  if (key === "regex") {
    pt_br.messages.regex = "Campo com formato inválido.";
  }
}
for (let key in pt_br.messages) {
  extend(key, rules[key]);
}

localize("pt_BR", pt_br);

export const TIPO_ENDERECO = [
  { id: 1, tipo_endereco: "RESIDENCIAL" },
  { id: 2, tipo_endereco: "COMERCIAL" }
];

export default {
  props: {
    tipoProprietario: { type: String }
  },
  computed: {
    ...mapState("auth", ["user_profile"]),
    ...mapGetters("servidor_publico", {
      servidorPublicoSelected: "servidorPublicoState"
    }),
    ...mapGetters("pensionista", {
      pensionistaSelected: "pensionistaState"
    }),
    ...mapGetters("endereco", {
      enderecoState: "enderecoState"
    }),
    user_profile_state: state => state.user_profile,
    cep() {
      return this.enderecos.map(endereco => endereco.cep);
    }
  },
  beforeMount() {
    this.getPapel();
  },
  data() {
    return {
      tipoEnderecoSelect: "",
      tipoEnderecoSelectOption: TIPO_ENDERECO,
      cepsBanidos: [],
      isFetching: true,
      papel: "",
      endereco: {
        logradouro: "",
        numeroLog: "",
        complemento: "",
        cep: "",
        bairro: "",
        cidade: "",
        uf: "",
        pais: "",
        tipoEndereco: "",
        tipoProprietario: this.tipoProprietario
      },
      enderecos: []
    };
  },
  mounted() {
    if (this.tipoProprietario === "SERVIDOR") {
      this.loadServidor();
      this.isFetching = false;
    }
    if (this.tipoProprietario === "PENSIONISTA") {
      this.loadPensionista();
      this.isFetching = false;
    }
  },
  methods: {
    ...mapActions("endereco", ["ActionSetENDERECO", "ActionGetCEP"]),
    ...mapActions("servidor_publico", [
      "ActionSubmitServidorAllData",
      "ActionSetSERVIDOR_PUBLICO"
    ]),
    ...mapActions("pensionista", [
      "ActionSubmitPensionistaAllData",
      "ActionSetPENSIONISTA"
    ]),
    addNovoEndereco: function() {
      this.ActionSetENDERECO(this.enderecos);
      this.enderecos.push(Vue.util.extend({}, this.endereco));
    },
    removeEndereco: function(index) {
      Vue.delete(this.enderecos, index);
      this.ActionSetENDERECO(this.enderecos);
    },
    emitToParent(value) {
      this.$emit("nextRoute", value);
    },
    validarProximo() {
      let checkerror = true;
      this.$refs.observer.validate();
      this.delay(1000).then(() => {
        if (this.$refs.observer.errors) {
          checkerror = Object.keys(this.$refs.observer.errors).some(key => {
            let checkerrorArray =
              this.$refs.observer.errors[key].length > 0 ? true : false;
            return checkerrorArray;
          });
          if (!checkerror) {
            this.ActionSetENDERECO(this.enderecos);
            this.emitToParent("telefones");
            if (this.tipoProprietario === "SERVIDOR") {
              window._Vue.$router
                .push({ name: "Novos Telefones" })
                .catch(err => {});
            } else if (this.tipoProprietario === "PENSIONISTA") {
              window._Vue.$router
                .push({ name: "Novos Telefones Pensionista" })
                .catch(err => {});
            }
          }
        }
      });
    },
    async loadForm() {
      if (this.enderecoState.length > 0) {
        Object.keys(this.enderecoState).map(key => {
          return this.enderecos.push(
            Vue.util.extend({}, this.enderecoState[key])
          );
        });
      } else {
        this.enderecos = JSON.parse(
          JSON.stringify([
            {
              logradouro: "",
              numeroLog: "",
              complemento: "",
              cep: "",
              bairro: "",
              cidade: "",
              uf: "",
              pais: "",
              tipoEndereco: "",
              tipoProprietario: this.tipoProprietario
            }
          ])
        );
      }
    },
    async loadServidor() {
      if (
        this.servidorPublicoSelected.cpfServidor &&
        this.servidorPublicoSelected !== "" &&
        this.servidorPublicoSelected !== null
      ) {
        this.loadForm();
      } else {
        Swal.fire({
          title: "ATENÇÃO!",
          icon: "warning",
          text: "Por favor preencha o Cadastro antes de avançar as Etapadas !",
          customClass: {
            confirmButton: "md-button md-warning btn-fill"
          },
          buttonsStyling: false
        });
        window._Vue.$router.push({ name: "Novo Cadastro" }).catch(err => {});
      }
    },
    async loadPensionista() {
      if (
        typeof this.pensionistaSelected !== "undefined" &&
        this.pensionistaSelected !== "" &&
        this.pensionistaSelected !== null &&
        this.pensionistaSelected.cpfPensionista
      ) {
        this.loadForm();
      } else {
        Swal.fire({
          title: "ATENÇÃO!",
          icon: "warning",
          text: "Por favor preencha o Cadastro antes de avançar as Etapadas !",
          customClass: {
            confirmButton: "md-button md-warning btn-fill"
          },
          buttonsStyling: false
        });
        window._Vue.$router
          .push({ name: "Novo Cadastro Pensionista" })
          .catch(err => {});
      }
    },
    async submit() {
      try {
        this.ActionSetENDERECO(this.enderecos);
        let papel = this.papel;
        //let form = this.dependentes;
        this.enderecos.map(end => {
          Object.keys(end).map(key => {
            if (key === "idEntidade") {
              if (this.tipoProprietario === "SERVIDOR") {
                end[key] = this.servidorPublicoSelected.idEntidade;
              }
              if (this.tipoProprietario === "PENSIONISTA") {
                end[key] = this.pensionistaSelected.idEntidade;
              }
            }
            return end[key];
          });
        });
        if (this.tipoProprietario === "SERVIDOR") {
          this.ActionSubmitServidorAllData({
            papel
          }).then(response => {
            checkAllResponses(response);
          });
        }
        if (this.tipoProprietario === "PENSIONISTA") {
          this.ActionSubmitPensionistaAllData({
            papel
          }).then(response => {
            checkAllResponsesPensionista(response);
          });
        }
      } catch (err) {
        this.handleError(err);
      }
    },
    getPapel() {
      this.papel = this.user_profile_state.papel;
    },
    handleError(err) {
      console.log(err);
      if (
        err === 400 ||
        err === 500 ||
        err === 404 ||
        err.status === 400 ||
        err.status === 500 ||
        err.status === 404
      ) {
        this.mensagem = "Aplicação temporariamente indisponível.";
      } else if (err.status === 401 || err.data === "Unauthorized") {
        this.mensagem = "Operação não Autorizada";
      } else {
        if (err.body) {
          this.mensagem = err.body.mensagem;
        } else if (err.status === 0) {
          this.mensagem =
            "Verifique sua conexão com a internet ou aguarde restabelecer conexões.";
        } else if (err.status === 409) {
          this.mensagem = err.body.mensagem;
        } else {
          this.mensagem =
            "Operação temporariamente indisponível tente mais tarde.";
        }
      }
      Swal.fire({
        title: "Erro!",
        icon: "error",
        text: this.mensagem,
        customClass: {
          confirmButton: "md-button md-danger btn-fill"
        },
        buttonsStyling: false
      });
    },
    delay(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }
  },
  watch: {
    cep(val) {
      //let parsedobj = JSON.parse(JSON.stringify(val));
      //console.log(parsedobj);
      Object.keys(this.enderecos).map(key => {
        if (this.enderecos[key].cep === val[key]) {
          let cep = cepMask(val[key]);
          this.enderecos[key].cep = cep;
          if (/^[0-9]{5}-[0-9]{3}$/.test(cep)) {
            //let teste = this.cepsBanidos.filter(function(reg) {
            //  return reg.cep === cep;
            //});
            try {
              let papel = this.papel;
              this.ActionGetCEP({ cep, papel }).then(response => {
                if (response.body.erro) {
                  //this.cepsBanidos.push({ cep: cep, key: key });
                  Swal.fire({
                    //title: "Sucesso",
                    icon: "warning",
                    text:
                      "CEP inválido tente outro cep ou complete manualmente os campos.",
                    showCancelButton: false,
                    customClass: {
                      confirmButton: "md-button md-success btn-fill"
                    },
                    buttonsStyling: false,
                    confirmButtonText: "Ok"
                  });
                } else {
                  let logradouro = response.body.logradouro;
                  let bairro = response.body.bairro;
                  let cidade = response.body.localidade;
                  let uf = response.body.uf;

                  this.enderecos[key].logradouro === ""
                    ? (this.enderecos[key].logradouro = logradouro)
                    : "";
                  this.enderecos[key].bairro === ""
                    ? (this.enderecos[key].bairro = bairro)
                    : "";
                  this.enderecos[key].cidade === ""
                    ? (this.enderecos[key].cidade = cidade)
                    : "";
                  this.enderecos[key].uf === ""
                    ? (this.enderecos[key].uf = uf)
                    : "";
                  this.enderecos[key].pais === ""
                    ? (this.enderecos[key].pais = "BRASIL")
                    : "";
                }
              });
            } catch (err) {
              console.log(err);
              this.handleError(err);
            }
          }
        }
      });
    }
  }
};
</script>
<style>
::v-deep .paginated-table .md-table-head-label {
  font-size: 0.875rem;
  font-weight: 400;
}
.md-table-cell {
  font-size: 12px;
}
#error {
  color: red;
  font-weight: 400;
  font-size: 0.65rem;
  margin-top: 0;
  transition: all 0.2s ease-in-out;
}

.md-card .md-card-actions {
  border: none;
  margin-left: 20px;
  margin-right: 20px;
}
.space-tool-bar {
  width: 50px;
  height: 50px;
}

.md-button.md-info.md-simple,
.md-button.md-info.md-simple:hover,
.md-button.md-info.md-simple:focus,
.md-button.md-info.md-simple:active,
.md-button.md-info.md-simple.active,
.md-button.md-info.md-simple:active:focus,
.md-button.md-info.md-simple:active:hover,
.md-button.md-info.md-simple.active:focus,
.md-button.md-info.md-simple.active:hover {
  margin-right: 5px;
  margin-left: 5px;
}

.md-button.md-success.md-simple,
.md-button.md-success.md-simple:hover,
.md-button.md-success.md-simple:focus,
.md-button.md-success.md-simple:active,
.md-button.md-success.md-simple.active,
.md-button.md-success.md-simple:active:focus,
.md-button.md-success.md-simple:active:hover,
.md-button.md-success.md-simple.active:focus,
.md-button.md-success.md-simple.active:hover {
  margin-right: 5px;
  margin-left: 5px;
}
.paginated-table .md-table-cell-container .md-button .md-button-content i {
  font-size: 24px !important;
}
::v-deep .modal-container {
  max-width: 720px;
}

.modal-text {
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif !important;
  font-size: 1.125em;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}

::v-deep .md-count {
  display: none;
}

#agrupamentoCampos {
  border: 1px solid rgba(128, 128, 128, 0.3);
  border-radius: 5px;
  padding-top: 12px;
  padding-bottom: 12px;
}

::v-deep .md-radio.md-disabled .md-radio-label,
.md-radio.md-disabled .md-radio-container {
  opacity: 1;
  border-color: rgba(0, 0, 0, 1) !important;
}

::v-deep .md-input {
  text-transform: uppercase;
}

.uppercase {
  text-transform: uppercase;
}

#container {
  display: flex; /* establish flex container */
  flex-direction: row; /* default value; can be omitted */
  flex-wrap: nowrap; /* default value; can be omitted */
  justify-content: space-between; /* switched from default (flex-start, see below) */
  background-color: lightyellow;
}
#container > div {
  width: 100px;
  height: 100px;
  border: 2px dashed red;
}
</style>
